/* .info 
{
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: opacity 0.5s linear;
    width: 0px;
    height: 0px;
    text-align: center;
} */
.info
{
  margin-top: 10px;
  position:absolute;
  align-content: center;
  width: 100%;
  
}

.outline {
  border: #0087ca;
  opacity: 1;
  border: 4px solid;
  border-radius: 25px;
  color:#0087ca;
  height: 100%;
  width: 100%;
  text-align: center;
}

/* 
.one {
 width: 33%;
 height:100%
} */

.row1 {
  display: flex;
  justify-content: space-around;
  padding-bottom: 5%;

}

.para {
  width: 40%;
  display: inline-block;
  align-items: center;
  padding-bottom: 3%;
  padding-top: 1%;
  font-size: 5mm;
  text-align: center;
  color: white;

}

    /* Clear floats after the columns */
  .row1:after {
    display: table;
    clear: both;
  }
  .item {
    height: 100%;
  }
/* 
  .item {
    padding: 5px;
    flex: 2;
  }

  .item:nth-child(2) {
    flex: 1;
  }

  .item:nth-child(3) {
    flex: .5;
  }
  
   Responsive layout - makes the four columns stack on top of each other instead of next to each other 
  @media screen and (max-width: 1200px) {
    .container { flex-wrap: wrap;  }

  } */