@import 'https://fonts.googleapis.com/css?family=Lily+Script+One';


.card {
  position: relative;
  width: 250px;
  height: 250px;
  align-self: center;
  object-fit: cover;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);

  transition: transform 1.5s;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY( 180deg ) ;
  transition: transform 1.5s;
}

.container,
.back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  color: #03446A;
  backface-visibility: hidden;
  object-fit: cover;
  text-align: left;
}

.back {
  background: black;
  color: white;
  transform: rotateY( 180deg );

}

img {
  object-fit: cover;
  max-width: 250px;
  max-height: 250px;
  position: relative;
}