 *{
  font-family: 'Inconsolata', monospace;

} 
html, body {
  width: 100%;
  height:100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.box .row {
}


.box .row.header {
  flex: 0 1 auto;
  color: white;
  text-align: center;
  background-color: black;
}

.box .row.cont {
  flex: 1 1 auto;
  padding: 2%;
  background-color: black;
  align-items: center;
  flex-direction: columns;
}

.box .row.footer {
  flex: 0 1 40px;
  color: white;
  text-align: center;
  padding-top: 7px;
  font-size: 5mm;
  background-color: black;

}

/* h1 {
  color: white;
  position: absolute;
  left: 50%;
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  
}

h1.top {
  top: 0;
  transform: translateX(-50%);
  width: max-content;
  padding: 5px;
} */

html, body{
  height: 100%;
  margin: 0;
}
