/* .info 
{
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: opacity 0.5s linear;
    width: 0px;
    height: 0px;
    text-align: center;
} */
.info-education
{
  margin-top: 10px;
  position:absolute;
  align-content: center;
  width: 100%;
  
}

.outline-education {
  border: #adff2f;
  opacity: 1;
  border: 4px solid;
  border-radius: 25px;
  color: greenyellow;
  height: 100%;
  width: 100%;
  text-align: center;
}

/* 
.one {
 width: 33%;
 height:100%
} */

.row1-education {
  display: flex;
  justify-content: space-around;
  padding-bottom: 5%;

}

.para-education {
  width: 40%;
  display: inline-block;
  align-items: center;
  padding-bottom: 3%;
  padding-top: 1%;
  font-size: 5mm;
  text-align: center;
  color: white;

}
  
    /* Clear floats after the columns */
  .row1:after {
    display: table;
    clear: both;
  }
  .item {
    height: 100%;
  }
