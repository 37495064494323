.pannelMain
{
  position: absolute;
  margin-top: 10px;
  width: 100%;
  align-items: center;
}

.globe {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.socials {
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 60px;
  width: 40%;
  padding-top: 20px;
}


.canvas {
    overflow: hidden;
}
#scene-container {
    width: 100%;
    height: 100%;
}